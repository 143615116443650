import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { noop } from 'utils/helpers';
import { loginRequest } from 'utils/msalConfig';
import {
  LS_ACCESS_TOKEN,
  LS_ID_TOKEN,
  LS_USER_ID,
} from 'utils/constants/localStorage';
import { useCompany } from 'hooks/useCompany';
import MainLoader from 'components/common/MainLoader';

const AuthContext = createContext({
  isAuthenticated: false,
  inProgress: false,
  idToken: null,
  accessToken: null,
  user: null,
  login: noop,
  logout: noop,
  fetchTokensIfAuthenticated: noop,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { instance, inProgress, accounts } = useMsal();
  const { getCompanyDetails, loading } = useCompany();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const idToken = localStorage.getItem(LS_ID_TOKEN);
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
  const [user, setUser] = useState(null);

  // Define the token fetching function inside the AuthProvider

  const fetchTokensIfAuthenticated = async () => {
    if (account && inProgress === InteractionStatus.None) {
      const request = {
        ...loginRequest,
        account: account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const idToken = response?.idToken;
        const accessToken = response?.accessToken;
        return { idToken, accessToken };
      } catch (error) {
        // Handle token acquisition error

        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    if (account && inProgress === InteractionStatus.None && isAuthenticated) {
      // Call the token fetching function
      fetchTokensIfAuthenticated().then(tokens => {
        if (tokens) {
          // Tokens were successfully fetched
          const { idToken, accessToken } = tokens;

          localStorage.setItem(LS_ID_TOKEN, idToken);
          localStorage.setItem(LS_ACCESS_TOKEN, accessToken);

          // get company details
          getCompanyDetails({ userEmail: account.username }, data => {
            setUser(data);
          });
        }
      });
    }
  }, [isAuthenticated, inProgress, account]);

  const login = () => {
    instance.loginRedirect(loginRequest);
  };

  const logout = (onSuccess = noop, onError = noop) => {
    localStorage.removeItem(LS_USER_ID);
    localStorage.removeItem(LS_ID_TOKEN);
    localStorage.clear();
    instance
      .logoutRedirect()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      })
      .finally(() => {});
  };

  const contextValue = {
    idToken,
    accessToken,
    isAuthenticated,
    inProgress,
    login,
    logout,
    fetchTokensIfAuthenticated,
    user,
    company: user?.company || {},
  };

  return loading ? (
    <MainLoader />
  ) : (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
