import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import { loginUserApi } from 'utils/constants/apis';
import { APP_NAME, DEFAULT_ERROR_MESSAGE } from 'utils/constants/app';
import { LS_USER_COMPANY_ID, LS_USER_ID } from 'utils/constants/localStorage';
import axiosInstance from 'utils/helpers/axios';

const UserContext = createContext({
  loading: false,
  auth: null,
  user: null,
  handleLogin: noop,
  clearSession: noop,
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const defaultCompanyId = localStorage.getItem(LS_USER_COMPANY_ID) || null;
  const defaultUserId = localStorage.getItem(LS_USER_ID) || null;
  const defaultAuth =
    defaultUserId && defaultCompanyId
      ? {
          userId: defaultUserId,
          companyId: defaultCompanyId,
        }
      : null;
  const [auth, setAuth] = useState(defaultAuth);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('app');

  const handleLogin = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(loginUserApi(), { ...payload, appName: APP_NAME })
      .then(data => {
        const { id, company } = data;
        const { id: companyId } = company;
        localStorage.setItem(LS_USER_ID, id);
        localStorage.setItem(LS_USER_COMPANY_ID, companyId);
        const auth = {
          userId: id,
          companyId: companyId,
        };
        setAuth(auth);
        setUser(data);
        enqueueSnackbar(t('Login successful'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        clearSession();
        onError(err);
      })
      .finally(() => {
        const auth = {
          userId: 1,
          companyId: 16,
        };
        setAuth(auth);
        // setCompany({
        //   id: 1,
        //   name: 'ERP Tohama',
        // });
      });
  };

  const clearSession = (onSuccess = noop) => {
    localStorage.removeItem(LS_USER_ID);
    localStorage.removeItem(LS_USER_COMPANY_ID);
    localStorage.clear();
    setAuth(null);
    onSuccess();
  };

  const contextValue = {
    loading,
    auth,
    user,
    handleLogin,
    clearSession,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
