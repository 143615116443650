import sidebar from './sidebar.json';
import app from './app.json';
import pages from './pages';
import common from './common';

const ja = {
  sidebar,
  app,
  common,
  pages,
};

export default ja;
