import React, { useState } from 'react';
import { DEFAULT_ERROR_MESSAGE, paginationState } from 'utils/constants/app';
import { noop } from 'utils/helpers';
import contractRequest from 'dummy/contractRequest.json';
import axiosInstance from 'utils/helpers/axios';
import { useSnackbar } from 'notistack';
import { getCompanyApi } from 'utils/constants/apis';

export const useCompany = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getCompanyDetails = (payload, onSuccess = noop, onError = noop) => {
    const { userEmail } = payload;
    setLoading(true);
    axiosInstance
      .get(getCompanyApi(userEmail))
      .then(data => {
        onSuccess(data);
        setLoading(false);
      })
      .catch(err => {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, {
          variant: 'error',
        });
        onError(err);
      });
  };

  return {
    loading,
    getCompanyDetails,
  };
};
