export const FALLBACK_IMAGE = 'https://bit.ly/broken-link';
export const LOGO = '/logo.svg';
export const EMPTY_DASHBOARD_ICON = '/empty-dashboard.jpeg';
export const PROFILE_BANNER_IMAGE = '/banner-pattern.jpeg';
export const DEFAULT_PROFILE_IMAGE = '/user-default-icon.png';
export const DEFAULT_COMPANY_IMAGE = '/company-logo2.svg';
export const DEFAULT_UNIVERSITY_IMAGE = '/company-logo2.svg';
export const UPLOAD_IMAGE = '/upload-icon.svg';
export const COPY_ICON = '/copyIcon.svg';
export const VIEW_JOB_EDIT_ICON = '/icons/viewJobEditIcon.svg';
export const VIEW_JOB_ATTACH_ICON = '/icons/attachIcon.svg';
export const UPLOAD_ICON = '/icons/UploadIcon.svg';
