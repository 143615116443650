import settings from './settings.json';
import notification from './notification.json';
import favorites from './favorites.json';
import course from './course.json';
import seminar from './seminar.json';
import job from './job.json';
import dashboard from './dashboard.json';
import login from './login.json';

const pages = {
  ...settings,
  ...notification,
  ...favorites,
  ...course,
  ...seminar,
  ...job,
  ...dashboard,
  ...login,
};

export default pages;
