import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguageCode } from 'utils/helpers';
import en from './en';
import ja from './ja';

const translationData = {
  en,
  ja,
};

const lng = getLanguageCode();

const debug = process.env.NODE_ENV === 'production';

i18n.use(initReactI18next).init({
  debug,
  fallbackLng: 'en',
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: translationData,
  lng,
});

export default i18n;
