import common from './common.json';
import sidebar from './sidebar.json';
import admin from './admin.json';
import app from './app.json';
import job from './job.json';
import pages from './pages';

const en = {
  app,
  admin,
  common,
  sidebar,
  job,
  pages,
};

export default en;
